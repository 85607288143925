var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full md:w-1/2 lg:w-1/4 company-dashboard-select-container",
        },
        [
          _c("v-select", {
            staticClass: "mb-4",
            attrs: {
              clearable: false,
              value: _vm.selectedDashboard,
              options: _vm.dashboardList,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("set-selected-dashboard", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-3/4 lg:pl-0 mb-4" },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c("vs-button", {
                attrs: {
                  type: "border",
                  "icon-pack": "feather",
                  icon: "icon-plus",
                  title: "Add New Dashboard",
                },
                on: {
                  click: function ($event) {
                    _vm.showAddDashboardPopup = true
                  },
                },
              }),
              _c("vs-button", {
                staticClass: "ml-2",
                attrs: {
                  disabled: _vm.isDashboardReadonly,
                  type: "border",
                  "icon-pack": "feather",
                  icon: "icon-edit-2",
                  title: "Edit Dashboard",
                },
                on: { click: _vm.editDashboard },
              }),
              _c("vs-button", {
                staticClass: "ml-2",
                attrs: {
                  disabled: _vm.isDashboardReadonly,
                  type: "border",
                  "icon-pack": "feather",
                  icon: "icon-trash",
                  title: "Delete Dashboard",
                },
                on: {
                  click: function ($event) {
                    _vm.showDeleteDashboardPopup = true
                  },
                },
              }),
              _c(
                "vs-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    disabled: _vm.isDashboardReadonly,
                    type: "border",
                    title: "Add Widget To Current Dashboard",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showAddWigetPopup = true
                    },
                  },
                },
                [_vm._v("Add Widget")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Add Dashboard",
            "accept-text": "Add",
            active: _vm.showAddDashboardPopup,
          },
          on: {
            cancel: _vm.closeAddDashboardDialog,
            close: _vm.closeAddDashboardDialog,
            accept: _vm.addDashboard,
            "update:active": function ($event) {
              _vm.showAddDashboardPopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Dahboard Name:"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "Dashboard", placeholder: "Dahboard Name" },
                  model: {
                    value: _vm.newDashboardName,
                    callback: function ($$v) {
                      _vm.newDashboardName = $$v
                    },
                    expression: "newDashboardName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mt-2" },
              [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Description:"),
                ]),
                _c("vs-textarea", {
                  staticClass: "w-full",
                  attrs: {
                    name: "Description",
                    placeholder: "Description",
                    counter: "256",
                    maxlength: "256",
                    rows: "3",
                  },
                  model: {
                    value: _vm.newDashboardDescription,
                    callback: function ($$v) {
                      _vm.newDashboardDescription = $$v
                    },
                    expression: "newDashboardDescription",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Dashboard",
            "accept-text": "Delete",
            active: _vm.showDeleteDashboardPopup,
          },
          on: {
            accept: _vm.deleteDashboard,
            "update:active": function ($event) {
              _vm.showDeleteDashboardPopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("div", {}, [
                _vm._v(
                  "Are you sure you want to delete the current dashboard?"
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Add Widget",
            "accept-text": "Add",
            active: _vm.showAddWigetPopup,
          },
          on: {
            cancel: _vm.closeAddWidgetDialog,
            close: _vm.closeAddWidgetDialog,
            accept: _vm.addWidget,
            "update:active": function ($event) {
              _vm.showAddWigetPopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Select Widget:"),
                ]),
                _c("v-select", {
                  attrs: { options: _vm.widgetOptions },
                  model: {
                    value: _vm.selectedWidget,
                    callback: function ($$v) {
                      _vm.selectedWidget = $$v
                    },
                    expression: "selectedWidget",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }