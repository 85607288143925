var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: {
        title: "Add Widget",
        active: _vm.show,
        "button-close-hidden": true,
        "data-backdrop": "static",
        "data-keyboard": "false",
      },
      on: {
        "update:active": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("label", { staticClass: "text-sm" }, [_vm._v("Select Widget:")]),
            _c("v-select", {
              attrs: { options: _vm.widgetOptions },
              model: {
                value: _vm.selectedWidget,
                callback: function ($$v) {
                  _vm.selectedWidget = $$v
                },
                expression: "selectedWidget",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "ml-auto" },
        [
          _c(
            "vs-button",
            {
              staticClass: "float-right ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.addWidget },
            },
            [_vm._v("Add")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "float-right",
              attrs: { color: "danger", type: "border" },
              on: { click: _vm.closeAddWidgetDialog },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }