var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("GChart", {
    attrs: {
      settings: { packages: ["geochart"], mapsApiKey: _vm.mapsApiKey },
      type: "GeoChart",
      data: _vm.series,
      options: _vm.chartOptions,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }