var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Website Statistics", subtitle: "" } },
    [
      _c("template", { slot: "actions" }, [
        _vm.appStatsUrlLink
          ? _c(
              "a",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { href: _vm.appStatsUrlLink, target: "_blank" },
              },
              [_vm._v("\n      View full stats (opens in new window)\n    ")]
            )
          : _vm._e(),
      ]),
      _vm.appStatsUrl
        ? _c("iframe", {
            ref: "appStatsFrame",
            staticStyle: { border: "0 solid #999", margin: "0 auto" },
            attrs: {
              id: "appStatsFrame",
              src: _vm.appStatsUrl,
              width: "100%",
              height: "650px",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }