var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-apex-charts", {
    attrs: {
      type: "bar",
      height: "350",
      options: _vm.chartOptions,
      series: _vm.chartData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }